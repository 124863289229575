import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import {Breadcrumb,Container,Row,Col} from "react-bootstrap";
import "./Breadcrumbs.scss"
// markup
const GuideBreadcrumbs = (props ) => {
  
  const data = useStaticQuery(graphql`
    query {
      allAllMenus {
        edges {
          node {
            URL
            Label
            Template
            Main_Parent {
            Label
            URL
            Alias
            }
            Alias
            Sub_Parent {
            Alias
            Label
            URL
            }
            _id
            Secondary_URL
            Sub_Parent_Secondary {
            Label
            URL
            }
          }
        }
      }
    }
  `);

	let PageURL = data.allAllMenus.edges.filter(item => item.node._id === props.id).pop().node;

  let URL = PageURL.Secondary_URL ? PageURL.Secondary_URL : PageURL.URL

  var parent_link = ''
  var parent_label = ''
  var sub_parent_link = ''
  var sub_parent_label = ''
  var sub_parent_link_1 = ''
  var sub_parent_label_1 = ''

  if(PageURL.Main_Parent != null) {
    parent_link = PageURL.Main_Parent?.URL
    parent_label = PageURL.Main_Parent?.Label
  }
  if(PageURL.Sub_Parent != null) {
    sub_parent_link = PageURL.Sub_Parent?.URL
    sub_parent_label = PageURL.Sub_Parent?.Label
  }
  if(PageURL.Sub_Parent_Secondary == null) {
    sub_parent_link_1 = PageURL.Sub_Parent_Secondary?.URL
    sub_parent_label_1 = PageURL.Sub_Parent_Secondary?.Label
  }

  return ( 
        <React.Fragment>
           <div className="breadcrumb-wrap">
           <Container>
             <Row>
               <Col md='12'>
                  <Breadcrumb>
                    <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                    {parent_label &&
                      <Breadcrumb.Item><Link to={`/${parent_link}`}>{parent_label}</Link></Breadcrumb.Item>
                    }{PageURL.Sub_Parent?.Label}
                    {sub_parent_label && parent_label &&
                      <Breadcrumb.Item><Link to={`/${parent_link}/${sub_parent_link}`}>{sub_parent_label}</Link></Breadcrumb.Item>
                    }
                    {sub_parent_label && parent_label && sub_parent_label_1 &&
                      <Breadcrumb.Item><Link to={`/${parent_link}/${sub_parent_link}/${sub_parent_link_1}`}>{sub_parent_label_1}</Link></Breadcrumb.Item>
                    }
                    {
                      <Breadcrumb.Item><Link to={`../`}>{PageURL.Label}</Link></Breadcrumb.Item>
                    }
                    {/* <Breadcrumb.Item href="#">Areas we cover</Breadcrumb.Item> */}
                    <Breadcrumb.Item active>{props.Pagename}</Breadcrumb.Item>
                  </Breadcrumb>
               </Col>
             </Row>
           </Container>
           </div>
        </React.Fragment>
    )
}
export default GuideBreadcrumbs