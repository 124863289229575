import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
// import Img from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser";
import PlayVideo from '../../../components/Play/PlayVideo';
import AdvisorImg from "../../../images/area-guide/advisors.jpg";
import { BRANCHES_PAGE_URL } from "../../common/site/constants"

import "./VideoBlock.scss"
// markup
const VideoBlock = (props) => {
  const [isPlay, setPlay] = useState(false);

  return (
    <React.Fragment>
      <div className="video-block-wrap components--AreaGuideDetails--VideoBlock--VideoBlock">
        <Container>
          <Row>
            <Col md="12">
              {props.Image &&
                // <Img fluid={props.Image.url_sharp.childImageSharp.fluid} alt={props.Image.alternativeText} />
                <GatsbyImage image={getImage(props.Image.url_sharp)} alt={props.Image.alternativeText} />
              }
            </Col>
            <Col md="8">
              {props.Content &&
                <div className="block-content">
                  <div className="block-text">
                    {parse(props.Content)}
                  </div>
                </div>
              }
            </Col>
            <Col md="4" className="block-content block-text">
              <div className="guide-details-right-block main-banner">
                <div className="first">
                  <form>
                    <div class="form-group">
                      <i class="icon-pin"></i>
                      <input placeholder="Enter your postcode or area" type="text" class="address form-control" />
                    </div>
                    <div className="btn-wrap row">
                      <Col lg={6} className="cta col-1">
                        <Link to="" className="btn btn-secondary">
                          <span>Selling or Letting?</span>Book a Valuation
                        </Link>
                      </Col>
                      <Col lg={6} className="cta col-2">
                        <Link to="" className="btn">
                          <span>Buying or Renting?</span>Find a Property
                        </Link>
                      </Col>
                    </div>
                  </form>

                  {props.People &&
                    <div className="advisor-info">
                      <div className="advisor-img">
                        <img src={props.People.Image.url} alt={props.People.Image.alternativeText} />
                      </div>
                      <div className="advisor-contact">
                        <span className="advisor-name">{props.People.Name}</span>
                        <span className="advisor-designation">{props.People.Designation}</span>
                        <a href={`tel:${props.People.Phone}`} className="phone-number">
                          <i className="icon-phone"></i>
                          <span>{props.People.Phone}</span>
                        </a>
                      </div>
                    </div>
                  }
                </div>

                <div className="info-text">
                  <p><b>Not local to Ashford?</b> We’ve got 40+ branches across Kent and surrounding areas – <Link to={BRANCHES_PAGE_URL.alias}>find your local one.</Link></p>
                </div>


              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default VideoBlock